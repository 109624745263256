import { FC } from 'react'
import StepContainerUI from '../../components/StepContainerUI'
import { PaymentProvider } from '../../consts'
import EvtBox from '../../elements/EvtBox'
import useDeviceType from '../../hooks/useDeviceType'
import { BraintreeProvider } from '../../providers/BraintreeProvider'
import { StripeProvider } from '../../providers/StripeProvider'
import { Order } from '../../types/orders/Order'
import { Address, OrderCheckoutResponse, PaymentMap } from '../../types/orders/OrderCheckoutResponse'
import { EVT } from '../../utils/evt'
import { BillingAddressProps } from './BillingAddressForm'

interface Props {
    id: string
    steps: Array<{ text: string; step: number }>
    myStep: number
    activeStep: number
    orderResponse: OrderCheckoutResponse
    billingAddress?: BillingAddressProps
    shippingMethodId?: number
    shippingAddress?: Address
    shipping?: number
    onNext: (order: Order) => void
    onBack: (step: number) => void
}

export const PaymentMethodForm: FC<Props> = ({
    id,
    steps,
    myStep,
    activeStep,
    orderResponse,
    billingAddress,
    shippingMethodId,
    shippingAddress,
    shipping,
    onNext,
    onBack,
}) => {
    const deviceType = useDeviceType()
    const isMobile = EVT.isMobile(deviceType)
    const index = steps.findIndex((s) => s.step === myStep)
    const isActive = activeStep === myStep
    const isDone = activeStep > myStep
    const title = steps[index].text

    return (
        <StepContainerUI
            id={id}
            index={index}
            title={title}
            isActive={isActive}
            isDone={isDone}
            scrollMarginTop={isMobile ? 430 : 360}
            onBack={() => onBack(myStep)}
        >
            {(isActive || isDone) && (
                <PaymentProviderWrapper
                    orderResponse={orderResponse}
                    billingAddress={billingAddress}
                    shippingMethodId={shippingMethodId}
                    shippingAddress={shippingAddress}
                    shipping={shipping}
                    onNext={onNext}
                />
            )}
        </StepContainerUI>
    )
}

const PaymentProviderWrapper: FC<{
    orderResponse: OrderCheckoutResponse
    billingAddress?: BillingAddressProps
    shippingMethodId?: number
    shippingAddress?: Address
    shipping?: number
    onNext: (order: Order) => void
}> = ({ orderResponse, billingAddress, shippingMethodId, shippingAddress, shipping, onNext }) => {
    return (
        <>
            {orderResponse.payments.some((e: PaymentMap) => e.paymentProviderId === PaymentProvider.stripe.id) && (
                <EvtBox>
                    <StripeProvider
                        orderResponse={orderResponse}
                        billingAddress={billingAddress}
                        shippingMethodId={shippingMethodId}
                        shippingAddress={shippingAddress}
                        shipping={shipping}
                        onNext={onNext}
                    ></StripeProvider>
                </EvtBox>
            )}

            {orderResponse.payments.some((e: PaymentMap) => e.paymentProviderId === PaymentProvider.braintree.id) && (
                <EvtBox>
                    <BraintreeProvider
                        orderResponse={orderResponse}
                        billingAddress={billingAddress}
                        shippingMethodId={shippingMethodId}
                        shippingAddress={shippingAddress}
                        shipping={shipping}
                        onNext={onNext}
                    ></BraintreeProvider>
                </EvtBox>
            )}
        </>
    )
}
