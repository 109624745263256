import { FC, ReactNode, useContext, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag'
import { CardContent, CardHeader } from '@mui/material'
import Card from '@mui/material/Card'
import { Theme, useTheme } from '@mui/material/styles'
import { ExpressCheckoutElement, PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { StripeExpressCheckoutElementConfirmEvent } from '@stripe/stripe-js'
import queryString from 'query-string'
import stripe from 'stripe'
import { PaymentProvider, PaymentProviders, PaymentType, SiteLocation, isAu, theme as themeName } from '../../consts'
import EvtBox from '../../elements/EvtBox'
import { EvtButtonPill } from '../../elements/EvtButton'
import EvtTypography from '../../elements/EvtTypography'
import { BillingAddressProps } from '../../pages/checkout/BillingAddressForm'
import { GetThemeStateContext } from '../../providers/CustomThemeProvider'
import { useLoader } from '../../providers/LoaderProvider'
import { useOrderPayment, useOrderStripeConfirmation } from '../../query/orders'
import { Order } from '../../types/orders/Order'
import { Address, OrderCheckoutResponse, PaymentMap } from '../../types/orders/OrderCheckoutResponse'
import { EVT } from '../../utils/evt'
import { PaymentMethodFormProps } from '../EFTPaymentMethod'

interface StripeResponse {
    data: {
        paymentIntentId: string
        clientSecret: string
        orderPaymentToken: string
        orderToken: string
        paymentToken: string
    }
}

interface AlipayResponse {
    data: {
        paymentIntentId: string
        clientSecret: string
        orderToken: string
        orderGuid: string
        returnUrl: string
    }
}

export const StripeCheckout: FC<{
    orderResponse: OrderCheckoutResponse
    shippingMethodId?: number
    shipping?: number
    shippingAddress?: Address
    billingAddress?: BillingAddressProps
    onNext: (order: Order) => void
}> = ({ orderResponse, shippingMethodId, shippingAddress, billingAddress, onNext }) => {
    const isLight = useContext(GetThemeStateContext).theme === themeName.LIGHT
    const elements = useElements()
    const stripejs = useStripe()
    const loader = useLoader()
    const location = useLocation()
    const navigate = useNavigate()
    const [activePaymentType, setActivePaymentType] = useState<number>(PaymentType.creditCard.id)
    const { mutate: sendStripeConfirmation } = useOrderStripeConfirmation()
    const { mutate: sendPayment } = useOrderPayment((error) => {
        loader(true)

        if (!stripe) {
            loader(false)
            return false
        }

        if (error.response.data.errorCode === 1234) {
            var stripeResponse = error.response.data as StripeResponse
            stripejs
                ?.handleNextAction({ clientSecret: stripeResponse.data.clientSecret })
                .then(({ error: hnaError, paymentIntent }) => {
                    if (hnaError || !paymentIntent) {
                        console.error(hnaError?.message)
                        loader(false)
                        alert(hnaError?.message)
                        return
                    }

                    sendStripeConfirmation(
                        {
                            paymentIntentId: paymentIntent.id,
                            orderToken: stripeResponse.data.orderToken,
                        },
                        {
                            onSuccess: (order) => {
                                loader(false)
                                onNext(order)
                            },
                        },
                    )
                })

            return true
        } else if (error.response.data.errorCode === 5678) {
            var alipayResponse = error.response.data as AlipayResponse
            var returnUrl = `${window.location.href}?orderToken=${alipayResponse.data.orderToken}`
            stripejs
                ?.confirmAlipayPayment(alipayResponse.data.clientSecret, { return_url: returnUrl })
                .then(({ error: hnaError, paymentIntent }) => {
                    if (hnaError || !paymentIntent) {
                        console.error(hnaError?.message)
                        loader(false)
                        alert(hnaError?.message)
                        return
                    }
                })

            return true
        }

        loader(false)
        return false
    })

    useEffect(() => {
        const params = queryString.parse(location.search)
        const paymentIntentId = params?.payment_intent
        const orderToken = params?.orderToken
        if (paymentIntentId && orderToken && typeof paymentIntentId === 'string' && typeof orderToken === 'string') {
            sendStripeConfirmation(
                {
                    paymentIntentId: paymentIntentId ?? '',
                    orderToken: orderToken,
                },
                {
                    onSuccess: (order) => {
                        loader(false)
                        onNext(order)
                    },

                    onError: function (err: any) {
                        // handle case where error occurs
                        // setSearchParams('')
                        EVT.error(err)
                        navigate(
                            {
                                search: '',
                            },
                            { replace: true },
                        )
                        window.location.reload()
                    },
                },
            )
        }
    }, [location])

    const isApplePay = orderResponse.payments.some(
        (e: PaymentMap) =>
            e.paymentProviderId === PaymentProvider.stripe.id && e.paymentMethodId === PaymentType.apple.id,
    )
    const isGooglePay = orderResponse.payments.some(
        (e: PaymentMap) =>
            e.paymentProviderId === PaymentProvider.stripe.id && e.paymentMethodId === PaymentType.google.id,
    )
    const isLink = orderResponse.payments.some(
        (e: PaymentMap) =>
            e.paymentProviderId === PaymentProvider.stripe.id && e.paymentMethodId === PaymentType.link.id,
    )

    var expressOptions = {
        buttonType: {
            applePay: 'buy' as const,
            googlePay: 'buy' as const,
        },
        paymentMethods: {
            googlePay: isGooglePay
                ? process.env.REACT_APP_ENV === 'development'
                    ? ('always' as const)
                    : ('auto' as const)
                : ('never' as const),
            applePay: isApplePay ? ('auto' as const) : ('never' as const),
            link: isLink ? ('auto' as const) : ('never' as const),
        },
        buttonTheme: {
            applePay: isLight ? ('black' as const) : ('white' as const),
            googlePay: isLight ? ('black' as const) : ('white' as const),
        },
    }

    const options = {
        layout: {
            type: 'accordion' as const,
            defaultCollapsed: false,
            radios: false,
            spacedAccordionItems: true,
        },
        fields: {
            billingDetails: {
                address: {
                    country: 'never' as const,
                },
            },
        },
    }

    const handlePayment = (values: PaymentMethodFormProps) => {
        if (orderResponse.id && billingAddress && shippingAddress) {
            sendPayment(
                {
                    id: orderResponse.id,
                    shippingMethodId: shippingMethodId,
                    braintreeDeviceData: values.braintreeDeviceData,
                    braintreeNonce: values.braintreeNonce,
                    paymentProviderId: values.paymentProviderId,
                    paymentTypeId: values.paymentTypeId,
                    postalOrderReference: billingAddress.ref,
                    billingAddress: billingAddress,
                    shippingAddress: shippingAddress,
                },
                {
                    onSuccess: (order) => {
                        onNext(order)
                    },
                },
            )
        }
    }

    const handleStripePayment = async (paymentTypeId: number) => {
        if (!stripe || !elements) {
            // Stripe.js hasn't yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return
        }

        elements.submit().then(({ error: submitError }) => {
            if (submitError) {
                console.error(submitError?.message)
                return
            }

            stripejs
                ?.createPaymentMethod({
                    elements: elements,
                    params: {
                        billing_details: {
                            address: {
                                country: isAu ? SiteLocation.AU : SiteLocation.NZ,
                            },
                        },
                    },
                })
                .then(({ error: pmError, paymentMethod }) => {
                    if (pmError) {
                        console.error(pmError?.message)
                        return
                    }

                    const data: PaymentMethodFormProps = {
                        paymentTypeId: paymentMethod.type === 'alipay' ? PaymentType.alipay.id : paymentTypeId,
                        paymentProviderId: PaymentProviders.stripe,
                        braintreeNonce: paymentMethod.id,
                    }

                    handlePayment(data)
                })
        })
    }

    const handleExpressCheckout = async (event: StripeExpressCheckoutElementConfirmEvent) => {
        handleStripePayment(
            event.expressPaymentType === 'apple_pay'
                ? PaymentType.apple.id
                : event.expressPaymentType === 'link'
                ? PaymentType.link.id
                : PaymentType.google.id,
        )
    }

    const handleExpressClick = async (event: any) => {
        const options = {
            emailRequired: true,
        }
        event.resolve(options)
    }

    const handleSubmit = async (event: any) => {
        event.preventDefault()
        if (activePaymentType === PaymentType.creditCard.id) {
            handleStripePayment(PaymentType.creditCard.id)
        } else {
            const data: PaymentMethodFormProps = {
                paymentTypeId: activePaymentType,
                paymentProviderId: PaymentProviders.stripe,
            }

            handlePayment(data)
        }
    }

    return (
        <EvtBox>
            <ExpressCheckoutElement
                key={`paymentElement-${isLight}`}
                onClick={handleExpressClick}
                onConfirm={handleExpressCheckout}
                options={expressOptions}
            />

            <EvtBox paddingY="20px">
                {orderResponse.payments.some(
                    (e: PaymentMap) =>
                        e.paymentProviderId === PaymentProvider.stripe.id &&
                        e.paymentMethodId === PaymentType.creditCard.id,
                ) && (
                    <>
                        <PaymentElement
                            options={options}
                            onChange={(e) => {
                                if (!e.collapsed) {
                                    setActivePaymentType(PaymentType.creditCard.id)
                                }
                            }}
                        />

                        <EvtButtonPill
                            sx={{
                                marginTop: '10px',
                                padding: '10px 20px',
                                width: 'fit-content',
                                minWidth: '150px',
                                display: activePaymentType === PaymentType.creditCard.id ? null : 'none',
                            }}
                            onClick={handleSubmit}
                        >
                            <ShoppingBagIcon sx={{ mr: '7px' }} />
                            Place Order
                        </EvtButtonPill>
                    </>
                )}

                {orderResponse.payments.some(
                    (e: PaymentMap) =>
                        e.paymentProviderId === PaymentProvider.stripe.id && e.paymentMethodId === PaymentType.eft.id,
                ) && (
                    <EvtBox paddingTop="20px">
                        <CollapseWrapper
                            text={PaymentType.eft.text}
                            isActive={activePaymentType === PaymentType.eft.id}
                            onClick={() => {
                                if (elements) {
                                    var paymentElement = elements.getElement('payment')
                                    paymentElement?.collapse()
                                }

                                setActivePaymentType(activePaymentType === PaymentType.eft.id ? 0 : PaymentType.eft.id)
                            }}
                        >
                            <EvtBox paddingBottom="25px">
                                <EvtTypography fontWeight="bold" paddingBottom="10px">
                                    Thanks for selecting Invoice.
                                </EvtTypography>
                                <EvtTypography>What happens next?</EvtTypography>
                                <EvtTypography>
                                    Once you place your order, your invoice will be returned. On receipt of full
                                    payment,
                                </EvtTypography>
                                <EvtTypography>
                                    your vouchers will be despatched or your eVouchers will be available for sending.
                                </EvtTypography>
                            </EvtBox>

                            <EvtButtonPill
                                sx={{ padding: '10px 20px', width: 'fit-content', minWidth: '150px' }}
                                onClick={handleSubmit}
                            >
                                <ShoppingBagIcon sx={{ mr: '7px' }} />
                                Place Order
                            </EvtButtonPill>
                        </CollapseWrapper>
                    </EvtBox>
                )}
            </EvtBox>
        </EvtBox>
    )
}

interface CollapseWrapperProps {
    children?: ReactNode
    text: string
    isActive?: boolean
    onClick: () => void
}

const CollapseWrapper: FC<CollapseWrapperProps> = ({ children, text, isActive, onClick }) => {
    const theme = useTheme()
    return (
        <Card variant="outlined" sx={cardStyle}>
            <CardHeader
                onClick={onClick}
                sx={cardHeaderStyle}
                title={text}
                titleTypographyProps={{
                    fontSize: '14px',
                    fontWeight: '600',
                    color: isActive ? theme.palette.payment.bgSelected : theme.palette.text.primary,
                }}
            ></CardHeader>
            {isActive && <CardContent>{children}</CardContent>}
        </Card>
    )
}

const cardHeaderStyle = (theme: Theme) => ({
    fontSize: '14px',
    fontWeight: '600',
    ':hover': {
        color: theme.palette.payment.bgSelected,
    },
    '.active': {
        color: theme.palette.payment.bgSelected,
    },
})

const cardStyle = (theme: Theme) => ({
    width: '100%',
    justifyContent: 'space-between',
    borderRadius: '15px',
    backgroundColor: theme.palette.background.default,
    border: `${theme.palette.border.default} 1px solid` as const,
})
