import { QueryClient } from 'react-query'
import { Recipient } from '../pages/order/AssignRecipient'
import { useCart } from '../providers/CartProvider'
import { Order, OrderProduct } from '../types/orders/Order'
import { Address, OrderCheckoutResponse } from '../types/orders/OrderCheckoutResponse'
import { PaginationResponse } from '../types/pagintation/PaginationResponse'
import { Pagination } from '../types/pagintation/PagintationQuery'
import orderUrls from '../urls/orderUrls'
import { EVT } from '../utils/evt'
import { storage } from '../utils/storage'
import { useEvtGetWithQuery, useEvtPostQuery, useEvtQuery } from './evtQuery'

export function useOrders(query: OrderQuery) {
    return useEvtGetWithQuery<OrdersResponse>(orderUrls.api.history, query, { cacheTime: 0 })
}

export function useOrder(id: string | undefined) {
    return useEvtGetWithQuery<Order>(orderUrls.api.details, {
        id: id,
    })
}

export function useOrderCreate() {
    return useEvtPostQuery<OrderCreateRequestProps, OrderCheckoutResponse>(orderUrls.api.create, {
        setQueryKeyData: (res) => {
            return { id: res.id, payments: res.payments }
        },
    })
}

export function useOrderCheckout(id: string | undefined) {
    return useEvtGetWithQuery<OrderCheckoutResponse>(orderUrls.api.checkout, {
        id: id,
    })
}

export function useOrderStripeConfirmation() {
    const { clearCart } = useCart()
    return useEvtPostQuery<OrderStripePaymentConfirmationRequest, Order>(orderUrls.api.stripeconfirm, {
        setQueryKeyData: (res) => {
            return { id: res.orderId }
        },
        onSuccess: () => {
            storage.remove('_evt_cart')
            clearCart()
        },
    })
}

export function useOrderPayment(onError?: (error: any, queryClient: QueryClient, request: any) => void | boolean) {
    const { clearCart } = useCart()
    return useEvtPostQuery<OrderPaymentRequest, Order>(orderUrls.api.payment, {
        setQueryKeyData: (res) => {
            return { id: res.orderId }
        },
        onSuccess: () => {
            storage.remove('_evt_cart')
            clearCart()
        },
        onError: onError ? onError : () => false,
    })
}

export function useAssignOrder() {
    return useEvtPostQuery<AssignOrderRequest, Order>(orderUrls.api.assign, {
        setQueryKeyData: (res) => {
            return { id: res.orderId }
        },
        successAnimationText: 'Products Assigned Successfully',
    })
}

export function useBraintreeClientToken() {
    return useEvtQuery<BraintreeClientResponse>(orderUrls.api.getbraintreeclienttoken)
}

export function useDigitalOrders(query: OrderQuery) {
    return useEvtGetWithQuery<OrderProductsResponse>(orderUrls.api.digital, query)
}

export function useOrderRecipients(query: RecipientQuery) {
    return useEvtGetWithQuery<RecipientsResponse>(orderUrls.api.recipients, query)
}

export function useOrderProductResend() {
    return useEvtPostQuery<{ OrderProductItemId: string }, RecipientModel>(orderUrls.api.orderProductResend, {
        onSuccess: (res, qc) => {
            updateRecipientList(res, qc)
        },
    })
}

export function useRecipientUpdate() {
    return useEvtPostQuery<RecipientUpdateParams, RecipientModel>(orderUrls.api.recipientUpdate, {
        onSuccess: (res, qc) => {
            updateRecipientList(res, qc)
        },
    })
}

export function useOrderDownloadEvouchers() {
    return useEvtPostQuery<{ id: string }, {}>(orderUrls.api.downloadeVouchers, {
        onSuccess: (res, qc) => {},
    })
}

const updateRecipientList = (res: RecipientModel, qc: QueryClient) => {
    const keys = EVT.getRelatedCacheKeys(qc, 'GET_ORDER_RECIPIENTS')
    const data = qc.getQueryData(keys) as RecipientsResponse
    const newRecipients = data.recipients?.items.map((item) => {
        if (item.id === res.id) {
            return res
        }
        return item
    })
    if (data?.recipients?.items) {
        data.recipients.items = newRecipients || []
    }
    qc.setQueryData(keys, data)
}

type OrdersResponse = {
    orders: PaginationResponse<Order>
    productTypes: Array<{ value: number; text: string }>
}

type OrderProductsResponse = {
    orderProducts: PaginationResponse<OrderProduct>
}

class OrderQuery extends Pagination {
    query?: string
    productType?: number
    productName?: string
    fromDate?: Date | null
    toDate?: Date | null
}

class RecipientQuery extends OrderQuery {
    senderName?: string
    recipientName?: string
    emailedTo?: string
    orderNumber?: string
    orderProductId?: string
}

export interface OrderCreateRequestProps {
    products: Array<OrderCreateProdcutProps>
}

export interface OrderCreateProdcutProps {
    id: number
    name: string
    quantity: number
    price: number
    productId?: number
    priceExclTax?: number
    expiryDate?: string
    promoCode?: string
    orderProductType?: number
}

export interface AssignOrderRequest {
    recipients: Array<Recipient>
    orderProductId: string
    from?: string
    greeting?: string
    message?: string
    bannerImageUrl?: string
}

type BraintreeClientResponse = {
    clientToken: string
}

export type OrderPaymentRequest = {
    id: string
    braintreeNonce?: string
    braintreeDeviceData?: string
    emailAddress?: string
    shippingMethodId?: number
    postalOrderReference?: string
    paymentProviderId: number
    paymentTypeId: number
    billingAddress: Address
    shippingAddress: Address
}

export type OrderStripePaymentConfirmationRequest = {
    paymentIntentId: string
    orderToken: string
}

export type OrderProductRecipient = {
    id: string
    sent: string | null
    name: string
    email: string
    message: string
    greeting: string
    bannerImageUrl: string
    from: string
}

type RecipientsResponse = {
    recipients?: PaginationResponse<RecipientModel>
}

export type RecipientModel = {
    id: string
    sent: string | null
    name: string
    email: string
    message: string
    greeting: string
    bannerImageUrl: string
    from: string
    productId: number
    productName: string
    productImageUrl: string
    orderId: string
    orderNumber: string
}

export type RecipientUpdateParams = {
    email: string
    orderProductItemId: string
    from: string
    greeting: string
    message: string
    send: boolean
}
