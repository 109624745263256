import { FC } from 'react'
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag'
import { PaymentType } from '../consts'
import EvtBox from '../elements/EvtBox'
import { EvtButtonPill } from '../elements/EvtButton'
import EvtTypography from '../elements/EvtTypography'

export const EFTMethod: FC<{ paymentProviderId: number; onNext: (values: PaymentMethodFormProps) => void }> = ({
    onNext,
    paymentProviderId,
}) => {
    return (
        <>
            <EvtBox paddingBottom="25px">
                <EvtTypography fontWeight="bold" paddingBottom="10px">
                    Thanks for selecting Invoice.
                </EvtTypography>
                <EvtTypography>What happens next?</EvtTypography>
                <EvtTypography>
                    Once you place your order, your invoice will be returned. On receipt of full payment,
                </EvtTypography>
                <EvtTypography>
                    your vouchers will be despatched or your eVouchers will be available for sending.
                </EvtTypography>
            </EvtBox>
            <EvtBox width="100%">
                <EvtButtonPill
                    sx={{ padding: '10px 20px', width: 'fit-content', minWidth: '150px' }}
                    onClick={() => onNext({ paymentTypeId: PaymentType.eft.id, paymentProviderId: paymentProviderId })}
                >
                    <ShoppingBagIcon sx={{ mr: '7px' }} />
                    Place Order
                </EvtButtonPill>
            </EvtBox>
        </>
    )
}

export interface PaymentMethodFormProps {
    braintreeNonce?: string
    braintreeDeviceData?: string
    paymentTypeId: number
    paymentProviderId: number
}
