import React, { FC, ReactNode, useEffect, useState } from 'react'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import { styled } from '@mui/material/styles'
import braintree from 'braintree-web'
import { EFTMethod, PaymentMethodFormProps } from '../components/EFTPaymentMethod'
import { BraintreeHostedFields } from '../components/braintree/BraintreeHostedFields'
import { BraintreePaypal } from '../components/braintree/BraintreePaypal'
import { PaymentProvider, PaymentType } from '../consts'
import EvtBox from '../elements/EvtBox'
import { EvtButton } from '../elements/EvtButton'
import EvtCollapse from '../elements/EvtCollapse'
import { EvtLoader } from '../elements/EvtLoader'
import { BillingAddressProps } from '../pages/checkout/BillingAddressForm'
import { useBraintreeClientToken, useOrderPayment } from '../query/orders'
import { Order } from '../types/orders/Order'
import { Address, OrderCheckoutResponse } from '../types/orders/OrderCheckoutResponse'

const GetBraintreeStateContext = React.createContext<any>(null)
const BraintreeProvider = (props: {
    orderResponse: OrderCheckoutResponse
    shippingMethodId?: number
    shipping?: number
    shippingAddress?: Address
    billingAddress?: BillingAddressProps
    onNext: (order: Order) => void
}) => {
    const { mutate: sendPayment } = useOrderPayment()
    const [activePaymentType, setActivePaymentType] = useState<number>(0)
    const handleMethodSwitch = (paymentType: number) => {
        if (paymentType !== activePaymentType) {
            setActivePaymentType(paymentType)
        } else {
            setActivePaymentType(0)
        }
    }
    const { data: braintreeResponse, isLoading } = useBraintreeClientToken()
    const [clientInstance, setClientInstance] = useState<any>()
    useEffect(() => {
        if (braintreeResponse) {
            braintree.client.create(
                {
                    authorization: braintreeResponse.clientToken,
                },
                function (err, ci) {
                    if (err) {
                        console.error(err)
                        return
                    }

                    braintree.dataCollector.create(
                        {
                            client: ci,
                            paypal: true,
                            kount: true,
                        },
                        function (err, dataCollectorInstance) {
                            if (err) {
                                setClientInstance(ci)
                                return
                            }

                            ci.deviceData = dataCollectorInstance?.deviceData
                            setClientInstance(ci)
                        },
                    )
                },
            )
        }
    }, [braintreeResponse])

    const handlePayment = (values: PaymentMethodFormProps) => {
        if (props.orderResponse.id && props.billingAddress && props.shippingAddress) {
            sendPayment(
                {
                    id: props.orderResponse.id,
                    shippingMethodId: props.shippingMethodId,
                    braintreeDeviceData: values.braintreeDeviceData,
                    braintreeNonce: values.braintreeNonce,
                    paymentProviderId: values.paymentProviderId,
                    paymentTypeId: values.paymentTypeId,
                    postalOrderReference: props.billingAddress.ref,
                    billingAddress: props.billingAddress,
                    shippingAddress: props.shippingAddress,
                },
                {
                    onSuccess: (order) => {
                        props.onNext(order)
                    },
                },
            )
        }
    }

    return (
        <GetBraintreeStateContext.Provider value={clientInstance}>
            <EvtLoader loading={isLoading || !clientInstance}>
                <EvtBox display="flex" flexDirection="column" gap="10px">
                    {props.orderResponse.payments
                        .filter(function (el) {
                            return el.paymentProviderId === PaymentProvider.braintree.id
                        })
                        .sort((a, b) => (a.paymentMethodId ?? 1) - (b.paymentMethodId ?? 1))
                        .map((payment) => {
                            switch (payment.paymentMethodId) {
                                case PaymentType.paypal.id: {
                                    return (
                                        <CollapseWrapper
                                            key={PaymentType.paypal.id}
                                            text={PaymentType.paypal.text}
                                            isActive={activePaymentType === PaymentType.paypal.id}
                                            onClick={() => handleMethodSwitch(PaymentType.paypal.id)}
                                        >
                                            <BraintreePaypal
                                                orderResponse={props.orderResponse}
                                                shipping={props.shipping}
                                                onNext={handlePayment}
                                            />
                                        </CollapseWrapper>
                                    )
                                }
                                case PaymentType.apple.id: {
                                    return (
                                        <TabButton
                                            key={PaymentType.apple.id}
                                            onClick={() => {
                                                handleMethodSwitch(PaymentType.apple.id)
                                                handlePayment({
                                                    paymentTypeId: PaymentType.apple.id,
                                                    paymentProviderId: PaymentProvider.braintree.id,
                                                })
                                            }}
                                        >
                                            {PaymentType.apple.text}
                                        </TabButton>
                                    )
                                }
                                case PaymentType.eft.id: {
                                    return (
                                        <CollapseWrapper
                                            key={PaymentType.eft.id}
                                            text={PaymentType.eft.text}
                                            isActive={activePaymentType === PaymentType.eft.id}
                                            onClick={() => handleMethodSwitch(PaymentType.eft.id)}
                                        >
                                            <EFTMethod
                                                onNext={handlePayment}
                                                paymentProviderId={PaymentProvider.braintree.id}
                                            />
                                        </CollapseWrapper>
                                    )
                                }
                                case PaymentType.creditCard.id: {
                                    return (
                                        <CollapseWrapper
                                            key={PaymentType.creditCard.id}
                                            text={PaymentType.creditCard.text}
                                            isActive={activePaymentType === PaymentType.creditCard.id}
                                            onClick={() => handleMethodSwitch(PaymentType.creditCard.id)}
                                        >
                                            <BraintreeHostedFields
                                                onNext={handlePayment}
                                                orderResponse={props.orderResponse}
                                            />
                                        </CollapseWrapper>
                                    )
                                }
                                default: {
                                    return <React.Fragment key={payment.paymentMethodId}></React.Fragment>
                                }
                            }
                        })}
                </EvtBox>
            </EvtLoader>
        </GetBraintreeStateContext.Provider>
    )
}

interface CollapseWrapperProps {
    children?: ReactNode
    text: string
    isActive?: boolean
    onClick: () => void
}

const CollapseWrapper: FC<CollapseWrapperProps> = ({ children, text, isActive, onClick }) => {
    return (
        <EvtBox>
            <TabButton onClick={onClick}>
                {text} <ArrowIconStyles fontSize="small" className={isActive ? 'arrow-down' : ''} />
            </TabButton>

            <EvtCollapse in={isActive}>
                <EvtBox paddingY="20px">{children}</EvtBox>
            </EvtCollapse>
        </EvtBox>
    )
}

interface TabButtonProps {
    children: ReactNode
    onClick: () => void
}
const TabButton: FC<TabButtonProps> = ({ children, onClick }) => {
    return (
        <EvtButton variant="contained" sx={buttonStyle} onClick={onClick}>
            {children}
        </EvtButton>
    )
}

const buttonStyle = () => ({
    width: '100%',
    maxWidth: '300px',
    paddingY: '15px',
    justifyContent: 'space-between',
    backgroundColor: '#2c2c2c',
    border: '1px solid #5c5c5c',
    borderRadius: '5px',
    ':hover': {
        backgroundColor: '#5c5c5c',
    },
})

const ArrowIconStyles = styled(ArrowForwardIosIcon)(({ theme }) => ({
    transition: 'all 0.1s linear',
    '&.arrow-down': {
        transform: 'rotate(90deg)',
    },
}))

const useBraintreeInstance = () => {
    const context = React.useContext(GetBraintreeStateContext)
    if (context === undefined) {
        throw new Error('useBraintree must be used within a BraintreeProvider')
    }

    return context
}

export { BraintreeProvider, GetBraintreeStateContext, useBraintreeInstance }
