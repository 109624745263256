import { config } from '../config'
import { API, createPageUrl } from '../urls'

const orderUrls = {
    api: {
        history: {
            url: config.baseUrl + '/api/orders/history',
            requiresAuth: true,
            method: 'GET',
            queryKey: 'GET_ORDERS',
        } as API,
        digital: {
            url: config.baseUrl + '/api/orders/digital',
            requiresAuth: true,
            method: 'GET',
            queryKey: 'GET_ORDERPRODUCTS',
        } as API,
        details: {
            url: config.baseUrl + '/api/orders/details',
            requiresAuth: true,
            method: 'GET',
            queryKey: 'GET_ORDER_DETAIL',
        } as API,
        checkout: {
            url: config.baseUrl + '/api/orders/checkout',
            requiresAuth: true,
            method: 'GET',
            queryKey: 'GET_ORDER_CHECKOUT',
        } as API,
        stripeconfirm: {
            url: config.baseUrl + '/api/orders/stripeconfirm',
            requiresAuth: true,
            method: 'POST',
            queryKey: 'GET_ORDER_DETAIL',
        } as API,
        assign: {
            url: config.baseUrl + '/api/orders/assign',
            requiresAuth: true,
            method: 'GET',
            queryKey: 'GET_ORDER_DETAIL',
        } as API,
        assignPost: {
            url: config.baseUrl + '/api/orders/assign',
            requiresAuth: true,
            method: 'POST',
            queryKey: 'POST_ORDER_ASSIGN',
        } as API,
        create: {
            url: config.baseUrl + '/api/orders/create',
            requiresAuth: true,
            method: 'POST',
            queryKey: 'GET_ORDER_CHECKOUT',
        } as API,
        payment: {
            url: config.baseUrl + '/api/orders/payment',
            requiresAuth: true,
            method: 'POST',
            queryKey: 'GET_ORDER_DETAIL',
        } as API,
        recipients: {
            url: config.baseUrl + '/api/orders/recipients',
            requiresAuth: true,
            method: 'GET',
            queryKey: 'GET_ORDER_RECIPIENTS',
        } as API,
        getbraintreeclienttoken: {
            url: config.baseUrl + '/api/orders/getbraintreeclienttoken',
            requiresAuth: true,
            method: 'POST',
            queryKey: 'GET_ORDER_BT_TOKEN',
        } as API,
        orderProductResend: {
            url: config.baseUrl + '/api/orders/resendrecipient',
            requiresAuth: true,
            method: 'POST',
            queryKey: 'POST_ORDER_PRODUCT_RESEND',
        } as API,
        recipientUpdate: {
            url: config.baseUrl + '/api/orders/updaterecipient',
            requiresAuth: true,
            method: 'POST',
            queryKey: 'POST_RECIPIENT_UPDATE',
        } as API,
        invoice: {
            url: config.baseUrl + `/api/orders/invoice`,
            requiresAuth: false,
            method: 'GET',
        } as API,
        downloadeVouchers: {
            url: config.baseUrl + `/api/orders/downloadevouchers`,
            requiresAuth: true,
            method: 'POST',
        } as API,
    },
    pages: {
        index: createPageUrl({
            url: '/orders',
            text: 'Orders',
        }),
        details: createPageUrl({
            url: '/order/:id',
            text: 'Order Details',
        }),
        assign: createPageUrl({
            url: '/assign/:id',
            text: 'Assign',
        }),
    },
}

export default orderUrls
