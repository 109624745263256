import { FC, useRef } from 'react'
import braintree from 'braintree-web'
import { PaymentProviders, PaymentType } from '../../consts'
import { EvtBoxColumn } from '../../elements/EvtBox'
import { useBraintreeInstance } from '../../providers/BraintreeProvider'
import { OrderCheckoutResponse } from '../../types/orders/OrderCheckoutResponse'
import { EVT } from '../../utils/evt'
import { PaymentMethodFormProps } from '../EFTPaymentMethod'

export const BraintreePaypal: FC<{
    orderResponse: OrderCheckoutResponse
    onNext?: (values: PaymentMethodFormProps) => void
    shipping?: number
}> = ({ onNext, orderResponse, shipping }) => {
    const clientInstance = useBraintreeInstance()
    const paypalInstance = useRef<braintree.PayPalCheckout | undefined>(undefined)
    if (clientInstance && !paypalInstance.current) {
        braintree.paypalCheckout
            .create({
                client: clientInstance,
            })
            .then(function (paypalCheckoutInstance) {
                paypalInstance.current = paypalCheckoutInstance
                paypalCheckoutInstance
                    .loadPayPalSDK({
                        currency: process.env.REACT_APP_CURRENCY,
                        intent: 'capture',
                        'enable-funding': 'paylater',
                    })
                    .then(() => {
                        const paypal = window.paypal
                        let fundingSource = [paypal.FUNDING.PAYPAL, 'paylater']
                        fundingSource.forEach((fs) => {
                            paypal
                                .Buttons({
                                    fundingSource: fs.toString(),
                                    style: {
                                        // shape: ButtonShapeOption.Rect,
                                        // color: ButtonColorOption.Blue,
                                        // label: ButtonLabelOption.PayPal,
                                    },
                                    createOrder: function () {
                                        const amount =
                                            EVT.sum(orderResponse.products, (p) => p.price * p.quantity) +
                                            (shipping || 0)
                                        return paypalCheckoutInstance.createPayment({
                                            //@ts-ignore
                                            flow: 'checkout',
                                            //@ts-ignore
                                            intent: 'capture',
                                            currency: process.env.REACT_APP_CURRENCY,
                                            amount: amount,
                                            enableShippingAddress: false,
                                        })
                                    },

                                    // @ts-ignore
                                    onApprove: function (data: PayPalCheckoutTokenizationOptions) {
                                        // some logic here before tokenization happens below
                                        return paypalCheckoutInstance.tokenizePayment(data).then(function (payload) {
                                            // Submit payload.nonce to your server
                                            const data: PaymentMethodFormProps = {
                                                paymentTypeId: PaymentType.paypal.id,
                                                paymentProviderId: PaymentProviders.braintree,
                                                braintreeDeviceData: clientInstance.deviceData,
                                                braintreeNonce: payload.nonce,
                                            }

                                            onNext?.(data)
                                            paypalInstance.current = undefined
                                        })
                                    },

                                    onCancel: function () {
                                        // handle case where user cancels
                                    },

                                    onError: function (err: any) {
                                        // handle case where error occurs
                                        EVT.error(err)
                                    },
                                })
                                .render('#paypal-button')
                        })
                    })
            })
            .catch(function (err) {
                EVT.error(err)
            })
    }

    return (
        <EvtBoxColumn gap="20px" maxWidth="300px">
            <div id="paypal-button"></div>
        </EvtBoxColumn>
    )
}
