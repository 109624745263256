import { createTheme } from '@mui/material'
import { CustomPalette } from './theme'

const { palette } = createTheme()
const { augmentColor } = palette
const createColor = (mainColor: string) => augmentColor({ color: { main: mainColor } })

const Palette_Dark: CustomPalette = {
    termCondition: 'rgb(212 212 212)',
    formInput: {
        border: {
            error: 'red',
        },
    },
    input: {
        boxShadow: '0 0 0 100px black inset',
        border: '#898989',
    },
    promoTile: {
        text: '#c7c7c7',
    },
    process: {
        bar: '#424242',
    },
    carousel: {
        navBtn: {
            bgColor: 'rgba(0,0,0,0.5)',
            broderColor: 'rgba(0,0,0,0.5)',
        },
    },
    nav: {
        topBgColor: '#000000',
        sideBgColor: '#1c1c1c',
        btnHover: '#262c34',
        sideItemTx: '#a9a8a8',
        sideTitleTx: '#efefef',
        topTx: '#FFFFFF',
        navSideIcon: '#9f9f9f',
        navUserIcon: '#9b9ea2',
        cartIcon: '#ffffff',
    },
    payment: {
        bgHover: 'rgb(25, 90, 137)',
        bgSelected: 'rgb(36, 129, 196)',
    },
    calendar: {
        bgColor: '#424242',
        btnColor: '#2481c4',
    },
    modal: {
        bgColor: '#121212',
        inputBgColor: '#383838',
        closeBtn: {
            bgColor: '#000000',
            borderColor: '#484646',
        },
        dividerColor: '#494949',
    },
    menu: {
        bgColor: '#222427',
        headerBg: '#383a3b',
        headerTxt: '#8b8b8c',
    },
    fileUploader: {
        bgColor: '#222427',
        circleBg: '#2e3032',
        svgBg: '#3a3d3f',
        outerTxt: '#c6c9ce',
    },
    footer: {
        bgColor: '#1c1c1c',
    },
    orderSummary: {
        summaryBg: '#1f1f1f',
        checkoutBtnBg: '#2481c3',
    },
    pageBanner: {
        bgColor: '#1c1c1c',
    },
    productCard: {
        bgColor: '#313131',
        postBg: 'red',
    },
    text: {
        primary: '#ffffff',
        secondary: '#c7c7c7',
        disabled: '#9b9b9b',
    },
    evoucherTemplate: {
        bgColor: '#FFFFFF',
        bannerBg: '#808080',
        headerBg: '#000000',
        txt: {
            primary: '#000000',
            secondary: '#808080',
        },
    },
    border: {
        default: '#898989',
        primary: '#000000',
        secondary: '#FFFFFF',
        focused: '#90caf9',
    },
    stepContainerUI: {
        border: {
            default: 'white',
            secondary: '#323232',
        },
    },
    promoCarousel: {
        imgBg: '#2f3033',
    },
    table: {
        headerBg: '#1f1f1f',
        actionIcon: '#ffffff',
    },
    stepper: {
        bgColor: '#272727',
        lableComplete: '#19aa59',
    },
    assignAddCSV: {
        uploadBtnBg: '#3381c4',
        docInputBg: '#2e2e2e',
        border: '#515151',
    },
    shoppingCart: {
        itemHeader: '#1f1f1f',
    },
    actionMenu: {
        bgColor: '#1f1f1f',
    },
    carouse: {
        btn: '#1f1f1f',
        indicator: {
            primary: 'gray',
            secondary: 'lightgray',
        },
    },
    background: {
        default: '#000000',
        secondary: '#212121',
        reverse: '#ffffff',
    },
    line: {
        bgColor: '#535353',
    },
    btn: {
        pill: { border: '#515354' },
    },
    link: {
        primary: '#2f78fd',
    },
    summary: {
        text: '#c7c7c7',
    },
    divider: '#2f3033',
    logoUrl: 'https://cdn.eventcinemas.com.au/cdn/giftshop/content/img/corporate/logo-white.svg',
    footerUrl: 'https://cdn.eventcinemas.com.au/cdn/giftshop/content/img/corporate/footer-icons.png',
    btnPrimary: createColor('#2481c4'),
    discountedPrice: 'green',
    homeLeftStripesUrl: 'https://cdn.eventcinemas.com.au/cdn/giftshop/content/img/corporate/left-side-stripes.svg',
    homeRightStripesUrl: 'https://cdn.eventcinemas.com.au/cdn/giftshop/content/img/corporate/right-side-stripes.svg',
}

export const MuiComponents_Dark = {
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    color: 'white',
                    '&.Mui-disabled': {
                        color: 'lightgray',
                        backgroundColor: 'gray',
                        cursor: 'not-allowed',
                        pointerEvents: 'unset',
                    },
                },
            },
        },
        MuiCssBaseline: {
            styleOverrides: {
                '@font-face': {
                    fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
                },
            },
        },
    },
}

export default Palette_Dark
