import { FC, useEffect, useState } from 'react'
import { SelectChangeEvent, Theme, useMediaQuery, useTheme } from '@mui/material'
import FileUploader from '../../components/FIleUploader'
import { ImageItem, ImageItemSkeleton } from '../../components/ImageItem'
import StepContainerUI, { StepContinueButton } from '../../components/StepContainerUI'
import EvtBox from '../../elements/EvtBox'
import EvtDivider from '../../elements/EvtDivider'
import EvtGrid from '../../elements/EvtGrid'
import EvtImageList from '../../elements/EvtImageList'
import EvtPagination from '../../elements/EvtPagination'
import { EvtSelect } from '../../elements/EvtSelect'
import EvtTypography from '../../elements/EvtTypography'
import useDeviceType from '../../hooks/useDeviceType'
import { eVoucherImage, useEvoucherImages } from '../../query/images'
import { EVT } from '../../utils/evt'
import { STEP } from './Assign'

interface Props {
    activeStepIndex: number
    imageStep: STEP
    image: eVoucherImage | null
    onNext: (image: eVoucherImage) => void
    onBack: () => void
    onChange: (image: eVoucherImage | null) => void
}

const AssignSelectImage: FC<Props> = ({ activeStepIndex, imageStep, image, onNext, onBack, onChange }) => {
    const isActive = activeStepIndex === imageStep.index
    const isDone = activeStepIndex > imageStep.index
    const deviceType = useDeviceType()
    const isDesktop = EVT.isDesktop(deviceType)
    const [selectedImage, setSeletedImage] = useState<eVoucherImage | null>(image)
    const [filter, setFilter] = useState<string>('All')
    const { data: imageList, isLoading: isImageLoading } = useEvoucherImages()

    useEffect(() => {
        if (selectedImage === null) {
            setFilter('All')
        }
    }, [selectedImage])

    const categoryOptions = [
        { value: 'All', text: 'All' },
        { value: 'userImages', text: 'Your Images' },
    ]
    imageList?.evoucherImages?.forEach((image) => {
        if (!categoryOptions.some((e: { value: string; text: string }) => e.value === image.Category)) {
            categoryOptions.push({ value: image?.Category || '', text: image?.Category || '' })
        }
    })

    let filteredImages: Array<eVoucherImage> = []
    if (imageList) {
        if (imageList.userEvoucherImages) {
            if (filter === 'userImages' || filter === 'All') filteredImages = [...imageList.userEvoucherImages]
        }
        if (imageList.evoucherImages) {
            filteredImages = [
                ...filteredImages,
                ...imageList.evoucherImages.filter((image) => image.Category === filter || filter === 'All'),
            ]
        }
    }

    const handleNext = () => {
        selectedImage && onNext(selectedImage)
    }
    const handleBack = () => {
        onBack()
    }
    const handleFilter = (e: SelectChangeEvent<unknown>) => {
        const category = e.target.value as string
        setFilter(category)
    }
    const handleImageClick = (image: eVoucherImage) => {
        if (selectedImage?.imageUrl === image.imageUrl) {
            setSeletedImage(null)
            onChange(null)
        } else {
            setSeletedImage(image)
            onChange(image)
        }
    }
    const onImageUploadSuccess = () => {
        if (imageList) {
            handleImageClick(imageList.userEvoucherImages[0])
        }
    }

    return (
        <StepContainerUI
            id={imageStep.id}
            index={imageStep.index}
            title="Select Image"
            isAssign={true}
            isActive={isActive}
            isDone={isDone}
            scrollMarginTop={350}
            onBack={handleBack}
        >
            {isActive && (
                <>
                    <EvtGrid container mb="20px" spacing={5}>
                        <EvtGrid item md={6} xs={12}>
                            <EvtSelect
                                options={categoryOptions}
                                label="Category"
                                fullWidth
                                defaultValue="All"
                                onChange={handleFilter}
                            />
                        </EvtGrid>
                    </EvtGrid>
                    {isDesktop ? (
                        <SelectImageDesktop
                            filter={filter}
                            isImageLoading={isImageLoading}
                            filteredImages={filteredImages}
                            selectedImage={selectedImage}
                            onImageUploadSuccess={onImageUploadSuccess}
                            handleImageClick={handleImageClick}
                        />
                    ) : (
                        <SelectImageMobile
                            filter={filter}
                            isImageLoading={isImageLoading}
                            filteredImages={filteredImages}
                            selectedImage={selectedImage}
                            onImageUploadSuccess={onImageUploadSuccess}
                            handleImageClick={handleImageClick}
                        />
                    )}

                    <StepContinueButton
                        text="Continue to Message"
                        disabled={selectedImage === null}
                        onClick={handleNext}
                    />
                </>
            )}

            {isDone && selectedImage && (
                <EvtBox sx={imageItemStyle}>
                    <img alt="test" src={selectedImage.imageUrl} />
                </EvtBox>
            )}
        </StepContainerUI>
    )
}

export default AssignSelectImage

interface SelectImageProps {
    filter: string
    isImageLoading: boolean
    filteredImages: Array<eVoucherImage>
    selectedImage: eVoucherImage | null
    onImageUploadSuccess?: () => void
    handleImageClick: (image: eVoucherImage) => void
}

const SelectImageDesktop: FC<SelectImageProps> = ({
    filter,
    isImageLoading,
    filteredImages,
    selectedImage,
    onImageUploadSuccess,
    handleImageClick,
}) => {
    return (
        <EvtBox display="flex" height="280px" alignItems="flex-start">
            <ImageListSection
                filter={filter}
                isImageLoading={isImageLoading}
                filteredImages={filteredImages}
                selectedImage={selectedImage}
                handleImageClick={handleImageClick}
            />

            <EvtBox display="flex" alignItems="center">
                <EvtDivider
                    sx={{
                        marginX: '30px',
                        height: '230px',
                    }}
                    orientation="vertical"
                >
                    <EvtTypography>Or</EvtTypography>
                </EvtDivider>
                <FileUploader width="210px" height="260px" onUploadSuccess={onImageUploadSuccess} />
            </EvtBox>
        </EvtBox>
    )
}

const SelectImageMobile: FC<SelectImageProps> = ({
    filter,
    isImageLoading,
    filteredImages,
    selectedImage,
    onImageUploadSuccess,
    handleImageClick,
}) => {
    return (
        <EvtBox display="flex" flexDirection="column" pb="20px" alignItems="center">
            <ImageListSection
                filter={filter}
                isImageLoading={isImageLoading}
                filteredImages={filteredImages}
                selectedImage={selectedImage}
                handleImageClick={handleImageClick}
            />
            <EvtDivider
                sx={{
                    marginY: '20px',
                    width: '100%',
                }}
            >
                <EvtTypography>Or</EvtTypography>
            </EvtDivider>
            <FileUploader width="210px" height="260px" onUploadSuccess={onImageUploadSuccess} />
        </EvtBox>
    )
}

const ImageListSection: FC<SelectImageProps> = ({
    filter,
    isImageLoading,
    filteredImages,
    selectedImage,
    handleImageClick,
}) => {
    const theme = useTheme()
    const isDown_1700 = useMediaQuery(theme.breakpoints.down(1700))
    const isDown_1200 = useMediaQuery(theme.breakpoints.down(1200))
    const isDonw_950 = useMediaQuery(theme.breakpoints.down(950))
    const isDonw_660 = useMediaQuery(theme.breakpoints.down(660))
    let colSize = 2
    if (isDown_1700) colSize = 1
    if (isDown_1200) colSize = 3
    if (isDonw_950) colSize = 2
    if (isDonw_660) colSize = 1

    const [page, setPage] = useState(1)
    const total = filteredImages.length
    const count = Math.ceil(total / (colSize * 2))

    const indexStart = (page - 1) * colSize * 2
    const indexEnd = page * colSize * 2

    useEffect(() => {
        setPage(1)
    }, [colSize, filter])

    return (
        <EvtBox height="100%" display="flex" flexDirection="column" alignItems="center">
            <EvtImageList sx={{ margin: '0', height: '230px' }} cols={colSize} rowHeight={theme.image.height} gap={20}>
                {isImageLoading && EVT.range(0, colSize).map((row, rowIndex) => <ImageItemSkeleton key={rowIndex} />)}

                {filteredImages.slice(indexStart, indexEnd).map((image) => (
                    <ImageItem
                        key={image.id}
                        image={image}
                        width={`${theme.image.width}px`}
                        onClick={handleImageClick}
                        isactive={selectedImage?.id === image.id}
                    />
                ))}
            </EvtImageList>
            {count > 1 && (
                <EvtPagination
                    count={count}
                    page={page}
                    sx={{ pt: '8px' }}
                    onChange={(event: React.ChangeEvent<unknown>, page: number) => setPage(page)}
                />
            )}
        </EvtBox>
    )
}

const imageItemStyle = (theme: Theme) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    img: {
        height: `${theme.image.height}px`,
        width: `${theme.image.width}px`,
        WebkitFilter: 'brightness(30%)',
    },
})
