import homeUrls from '../urls/homeUrls'
import { useEvtPostQuery, useEvtQuery } from './evtQuery'

export function useSettings() {
    return useEvtQuery<Settings>(homeUrls.api.settings)
}

export function useContactUs() {
    return useEvtPostQuery<ContactUs, {}>(homeUrls.api.contactUs, {})
}

export type Settings = {
    private: string
    terms: string
    about: string
    contactUs: string
    faq: string
    footer: string
    abn: string
    metaTags: Array<MetaTag>
    contentCopy: ContentCopy
    stripeKey: string
}

type ContentCopy = {
    corporate_RedirectFromEt_Message: string
    corporate_User_IncorrectPassword_ForcePasswordReset: string
    corporate_User_JoinMessage: string
    corporate_Assign_CSVInfo: string
    corporate_Products_NoneAssigned: string
}

export type ContactUs = {
    email: string
    firstName: string
    lastName: string
    company: string
    contactNumber: string
    preferredMethod: string
    products: Array<string>
}

export type MetaTag = {
    url: string
    metaDescription: string
    metaKeywords: string
}
