import React from 'react'
import { useTheme } from '@mui/material'
import { Elements } from '@stripe/react-stripe-js'
import { StripeElementsOptions, loadStripe } from '@stripe/stripe-js'
import Stripe from 'stripe'
import { StripeCheckout } from '../components/stripe/StripeCheckout'
import { EvtLoader } from '../elements/EvtLoader'
import { BillingAddressProps } from '../pages/checkout/BillingAddressForm'
import { useSettings } from '../query/settings'
import { Order } from '../types/orders/Order'
import { Address, OrderCheckoutResponse } from '../types/orders/OrderCheckoutResponse'

const stripePublicKey = loadStripe(process.env.REACT_APP_PUBLIC_STRIPE_PUBLISHABLE_KEY || '')

const GetStripeStateContext = React.createContext<any>(null)
const StripeProvider = (props: {
    orderResponse: OrderCheckoutResponse
    shippingMethodId?: number
    shipping?: number
    shippingAddress?: Address
    billingAddress?: BillingAddressProps
    onNext: (order: Order) => void
}) => {
    const theme = useTheme()
    const { data: { stripeKey } = {} } = useSettings()
    const stripe = new Stripe(stripeKey ?? '')

    const handlePayment = (order: Order) => {
        props.onNext(order)
    }

    const elementsOptions: StripeElementsOptions = {
        mode: 'payment' as const,
        currency: process.env.REACT_APP_CURRENCY?.toLowerCase(),
        amount: props.orderResponse.totalPrice * 100,
        paymentMethodCreation: 'manual',
        // externalPaymentMethodTypes: props.orderResponse.paymentTypeIds.includes(PaymentType.paypal.id)
        //     ? ['external_paypal']
        //     : [],

        appearance: {
            theme: 'night' as const,
            variables: {
                borderRadius: '15px' as const,
                colorBackground: theme.palette.background.default,
                colorText: theme.palette.text.primary,
            },
            rules: {
                '.AccordionItem': {
                    backgroundColor: theme.palette.background.default,
                    border: `${theme.palette.border.default} 1px solid` as const,
                    boxShadow: 'none' as const,
                },
                '.AccordionItem:hover': {
                    color: theme.palette.payment.bgHover,
                },
                '.AccordionItem--selected': {
                    color: theme.palette.payment.bgSelected,
                },
                '.Input': {
                    border: `${theme.palette.border.default} 1px solid` as const,
                    boxShadow: 'none',
                },
                '.Input:focus': {
                    outline: `${theme.palette.border.default} 2px solid` as const,
                    boxShadow: 'none',
                },
                '.Input:hover': {
                    outline: `${theme.palette.border.default} 2px solid` as const,
                    boxShadow: 'none',
                },
                '.Block': {
                    boxShadow: 'none' as const,
                },
            },
        },
    }

    return (
        <GetStripeStateContext.Provider value={stripe}>
            <EvtLoader loading={!stripePublicKey}>
                <Elements stripe={stripePublicKey} options={elementsOptions}>
                    <StripeCheckout
                        onNext={handlePayment}
                        billingAddress={props.billingAddress}
                        shippingMethodId={props.shippingMethodId}
                        shippingAddress={props.shippingAddress}
                        shipping={props.shipping}
                        orderResponse={props.orderResponse}
                    />
                </Elements>
            </EvtLoader>
        </GetStripeStateContext.Provider>
    )
}

const useStripeInstance = () => {
    const context = React.useContext(GetStripeStateContext)
    if (context === undefined) {
        throw new Error('useStripe must be used within a StripeProvider')
    }

    return context
}

export { GetStripeStateContext, StripeProvider, useStripeInstance }
