import { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { useTheme } from '@mui/material'
import OrderSummary from '../../components/OrderSummary'
import StepperHeader from '../../components/StepperHeader'
import EvtBox from '../../elements/EvtBox'
import EvtGrid from '../../elements/EvtGrid'
import EvtTypography from '../../elements/EvtTypography'
import useDeviceType from '../../hooks/useDeviceType'
import { useEvtNavigate } from '../../hooks/useEvtNavigate'
import { useOrderCheckout } from '../../query/orders'
import { Order } from '../../types/orders/Order'
import { Address } from '../../types/orders/OrderCheckoutResponse'
import cartUrls from '../../urls/cartUrls'
import checkoutUrls from '../../urls/checkoutUrls'
import { EVT } from '../../utils/evt'
import { BillingAddressForm, BillingAddressProps } from './BillingAddressForm'
import { PaymentMethodForm } from './PaymentMethodForm'
import { ShippingAddressForm } from './ShippingAddressForm'
import { ShippingMethodForm, ShippingMethodFormProps } from './ShippingMethodForm'

enum steps {
    billingAddress,
    shippingAddress,
    shippingMethod,
    payment,
    confirm,
}

interface STEP {
    text: string
    step: number
    id: string
}

const STEP_BILLING: STEP = { text: 'Billing Details', step: steps.billingAddress, id: 'billing-section' }
export const STEP_SHIPPING: STEP = { text: 'Shipping Details', step: steps.shippingAddress, id: 'shipping-section' }
const STEP_SHIPPING_METHOD: STEP = {
    text: 'Shipping Method',
    step: steps.shippingMethod,
    id: 'shipping-method-section',
}
const STEP_PAYMENT: STEP = { text: 'Payment Method', step: steps.payment, id: 'payment-section' }
const STEP_CONFIRM: STEP = { text: 'Confirmation', step: steps.confirm, id: 'confirm-section' }

const Checkout = () => {
    const theme = useTheme()
    const { id } = useParams()
    const deviceType = useDeviceType()
    const isMobile = EVT.isMobile(deviceType)
    const navigate = useEvtNavigate()

    const { data: orderResponse } = useOrderCheckout(id)

    const electronicSteps = [STEP_BILLING, STEP_PAYMENT, STEP_CONFIRM]
    const [physicalSteps, setPhysicalSteps] = useState([STEP_BILLING, STEP_SHIPPING_METHOD, STEP_PAYMENT, STEP_CONFIRM])

    const [activeStep, setActiveStep] = useState<number>(steps.billingAddress)
    const [billingAddress, setBillingAddress] = useState<BillingAddressProps | undefined>(undefined)
    const [shippingAddress, setShippingAddress] = useState<Address | undefined>(undefined)
    const [shippingMethod, setShippingMethod] = useState<ShippingMethodFormProps | undefined>(undefined)
    const [isSkipShipping, setIsSkipShipping] = useState<boolean>(true)
    const [isFree, setIsFree] = useState<boolean>(false)

    const hasPhysical = EVT.any(orderResponse?.shippingMethods)
    let validStepDetails = hasPhysical ? physicalSteps : electronicSteps
    validStepDetails = isFree ? [STEP_BILLING, STEP_CONFIRM] : validStepDetails
    const validSteps = validStepDetails.map((a) => a.step)
    const stepNames = validStepDetails.map((a) => a.text)
    const location = useLocation()

    useEffect(() => {
        setBillingAddress(orderResponse?.addresses[0])
        if (orderResponse && orderResponse.products.length === 1) {
            const total = orderResponse.products.reduce((sum, product) => sum + product.price * product.quantity, 0)
            setIsFree(total === 0)
            setShippingAddress(total === 0 ? orderResponse.addresses[0] : shippingAddress)
            if (location.search) {
                setActiveStep(steps.payment)
            }
        }
    }, [orderResponse, location])

    const handlePayment = (order: Order) => {
        navigate(checkoutUrls.pages.confirm, order.orderId)
    }

    const handleShipAddrsCheck = (isSkipShipping: boolean) => {
        setIsSkipShipping(isSkipShipping)
        if (isSkipShipping) {
            physicalSteps[0].text = 'Billing / Shipping Details'
            setPhysicalSteps(physicalSteps.filter((s) => s.step !== steps.shippingAddress))
        } else {
            physicalSteps[0].text = 'Billing Address'
            physicalSteps.splice(1, 0, STEP_SHIPPING)
            setPhysicalSteps(physicalSteps)
        }
    }

    const handleNext = (values: BillingAddressProps | Address | ShippingMethodFormProps) => {
        let element: HTMLElement | undefined
        switch (activeStep) {
            case steps.billingAddress: {
                setBillingAddress(values as BillingAddressProps)
                if (isSkipShipping) {
                    setShippingAddress(values as Address)
                    element = document.getElementById(STEP_SHIPPING_METHOD.id) as HTMLElement
                } else {
                    setShippingAddress(undefined)
                    element = document.getElementById(STEP_SHIPPING.id) as HTMLElement
                }

                break
            }
            case steps.shippingAddress: {
                setShippingAddress(values as Address)
                element = document.getElementById(STEP_SHIPPING_METHOD.id) as HTMLElement
                break
            }
            case steps.shippingMethod: {
                setShippingMethod(values as ShippingMethodFormProps)
                element = document.getElementById(STEP_PAYMENT.id) as HTMLElement
            }
        }
        const index = validSteps.indexOf(activeStep)
        element && element.scrollIntoView({ block: 'start', behavior: 'smooth' })
        setActiveStep(validSteps[index + 1])
    }

    const handleBack = (step: steps) => {
        setActiveStep(step)
    }

    const propertiesCreator = (step: STEP) => {
        return {
            id: step.id,
            steps: validStepDetails,
            myStep: step.step,
            activeStep: activeStep,
            onBack: handleBack,
        }
    }

    return (
        <EvtBox>
            <StepperHeader stepNames={stepNames} activeStepIndex={validSteps.indexOf(activeStep)} />
            <EvtBox
                sx={{
                    padding: isMobile ? '160px 100px 50px 100px' : '120px 100px 50px 100px',
                    [theme.breakpoints.down(theme.breakpoints.values.desktop)]: {
                        paddingX: '10px',
                    },
                }}
            >
                <EvtTypography fontSize="25px" sx={{ paddingBottom: '40px' }}>
                    Checkout
                </EvtTypography>

                <EvtGrid container spacing={10}>
                    <EvtGrid
                        item
                        xs={12}
                        lg={8}
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '70px',
                        }}
                    >
                        <BillingAddressForm
                            {...propertiesCreator(STEP_BILLING)}
                            isFree={isFree}
                            hasPhysical={hasPhysical}
                            orderId={orderResponse?.id}
                            savedAddress={orderResponse?.addresses[0]}
                            billingAddress={billingAddress}
                            shippingAddress={shippingAddress}
                            shipping={shippingMethod?.price}
                            isSkipShipping={isSkipShipping}
                            onShippingChange={handleShipAddrsCheck}
                            onPayment={handlePayment}
                            onNext={handleNext}
                        />
                        {!isSkipShipping && (
                            <ShippingAddressForm
                                {...propertiesCreator(STEP_SHIPPING)}
                                shippingAddress={shippingAddress}
                                onNext={handleNext}
                            />
                        )}

                        {orderResponse && hasPhysical && (
                            <ShippingMethodForm
                                {...propertiesCreator(STEP_SHIPPING_METHOD)}
                                shippingMethod={shippingMethod}
                                shippingMethods={orderResponse.shippingMethods}
                                onNext={handleNext}
                            />
                        )}
                        {!isFree && orderResponse && EVT.any(orderResponse?.payments) && (
                            <PaymentMethodForm
                                {...propertiesCreator(STEP_PAYMENT)}
                                orderResponse={orderResponse}
                                shipping={shippingMethod?.price}
                                billingAddress={billingAddress}
                                shippingMethodId={shippingMethod?.id}
                                shippingAddress={shippingAddress}
                                onNext={handlePayment}
                            />
                        )}
                    </EvtGrid>
                    <EvtGrid item xs={12} lg={4} display="flex" justifyContent="center">
                        <OrderSummary
                            products={orderResponse?.products}
                            isShowItems
                            shipping={
                                shippingMethod ? { name: shippingMethod.name, price: shippingMethod.price } : undefined
                            }
                            handleEdit={() => {
                                navigate(cartUrls.pages.cart)
                            }}
                        />
                    </EvtGrid>
                </EvtGrid>
            </EvtBox>
        </EvtBox>
    )
}

export default Checkout
