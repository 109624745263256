import { FC, useEffect, useState } from 'react'
import { Theme } from '@mui/material'
import { styled } from '@mui/material/styles'
import StepContainerUI, { StepContinueButton } from '../../components/StepContainerUI'
import EvtBox from '../../elements/EvtBox'
import { EvtCheckbox } from '../../elements/EvtCheckbox'
import EvtTableDetail, { Column } from '../../elements/EvtTableDetail'
import EvtTooltip from '../../elements/EvtTooltip'
import useDeviceType from '../../hooks/useDeviceType'
import { Order, OrderProduct } from '../../types/orders/Order'
import { EVT } from '../../utils/evt'
import { STEP } from './Assign'

interface Props {
    selectProduct: OrderProduct | null
    order: Order | undefined
    activeStepIndex: number
    productStep: STEP
    onNext: (product: OrderProduct) => void
    onBack: () => void
    onChange: (product: OrderProduct | null) => void
}

const AssignSelectProduct: FC<Props> = ({
    selectProduct,
    order,
    activeStepIndex,
    productStep,
    onNext,
    onBack,
    onChange,
}) => {
    const isActive = activeStepIndex === productStep.index
    const isDone = activeStepIndex > productStep.index
    const deviceType = useDeviceType()
    const isDesktop = EVT.isDesktop(deviceType)
    const electronic = order?.products.filter((p) => p.isElectronic && p.quantity - (p.totalSent || 0) > 0) || []
    const [selectedProduct, setSelectedProduct] = useState<OrderProduct | null>(
        electronic.length === 1 ? electronic[0] : null,
    )

    useEffect(() => {
        setSelectedProduct(selectProduct)
    }, [selectProduct])

    const handleNext = () => {
        selectedProduct && onNext(selectedProduct)
    }

    const handleSelected = (op: OrderProduct) => {
        setSelectedProduct(selectedProduct !== null && op.id === selectedProduct.id ? null : op)
        onChange(op)
    }

    const handleBack = () => {
        onBack()
    }

    const columns: Array<Column<OrderProduct>> = [
        {
            title: '',
            descriptor: (op) => (
                <EvtCheckbox
                    onClick={() => handleSelected(op)}
                    checked={!!selectedProduct && selectedProduct.id === op.id}
                />
            ),
            enabled: isActive,
        },
        {
            title: 'Product',
            descriptor: (op) => (
                <EvtBox sx={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
                    <ImageStyles alt={op.name || ''} src={op.cardImageUrl || ''} />
                </EvtBox>
            ),
        },
        {
            title: 'Product Name',
            descriptor: (op) => (
                <EvtTooltip title={op.name}>
                    <ProductNameStyles style={{}}>{op.name}</ProductNameStyles>
                </EvtTooltip>
            ),
        },
        { title: 'Price', descriptor: (op) => EVT.toCurrency(op.price) },
        { title: 'Quantity', descriptor: (op) => op.quantity },
        { title: 'Sent', descriptor: (op) => op.totalSent },
        { title: 'Remaining', descriptor: (op) => op.quantity - (op.totalSent || 0) },
    ]

    const columnsMobile: Array<Column<OrderProduct>> = [
        {
            title: '',
            descriptor: (op) => (
                <EvtCheckbox
                    onClick={() => handleSelected(op)}
                    checked={!!selectedProduct && selectedProduct.id === op.id}
                />
            ),
            enabled: isActive,
        },
        {
            title: 'Product Name',
            descriptor: (op) => (
                <EvtTooltip title={op.name}>
                    <ProductNameStyles style={{}}>{op.name}</ProductNameStyles>
                </EvtTooltip>
            ),
        },
        { title: 'Price', descriptor: (op) => EVT.toCurrency(op.price) },
        { title: 'Sent', descriptor: (op) => op.totalSent },
        { title: 'Remaining', descriptor: (op) => op.quantity - (op.totalSent || 0) },
    ]

    const items = selectedProduct ? [selectedProduct] : electronic

    return (
        <StepContainerUI
            id={productStep.id}
            index={productStep.index}
            title="Select Product"
            isAssign={true}
            isActive={isActive}
            isDone={isDone}
            scrollMarginTop={210}
            onBack={handleBack}
        >
            {isDone && (
                <EvtTableDetail
                    columns={isDesktop ? columns : columnsMobile}
                    items={items}
                    sx={(t) => tableStyle(t, isDone)}
                />
            )}

            {isActive && (
                <>
                    <EvtTableDetail
                        columns={isDesktop ? columns : columnsMobile}
                        items={items}
                        sx={(t) => tableStyle(t, isDone)}
                    />
                    <StepContinueButton
                        disabled={selectedProduct === null}
                        onClick={handleNext}
                        text="Continue to Image"
                    />
                </>
            )}
        </StepContainerUI>
    )
}

export default AssignSelectProduct

const tableStyle = (theme: Theme, isDone: boolean) => {
    const styles = isDone
        ? {
              td: { color: theme.palette.stepContainerUI.border.secondary, img: { WebkitFilter: 'brightness(30%)' } },
              th: { color: theme.palette.border.default },
          }
        : {
              td: {},
              th: {},
          }
    return {
        'td:nth-of-type(n+2), th:nth-of-type(n+2)': {
            textAlign: 'center',
        },
        ...styles,
    }
}

const ImageStyles = styled('img')(({ theme }) => ({
    height: '60px',
}))

const ProductNameStyles = styled('p')(({ theme }) => ({
    width: '100px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
}))
