import React, { FC } from 'react'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { SelectProps } from '@mui/material'
import EvtBox from './EvtBox'
import { EvtSelect, EvtSelectProps } from './EvtSelect'

interface Props extends EvtSelectProps {
    selectIcon?: Object
}

const EvtCustomSelect: FC<Props> = ({ selectIcon, options, children, ...props }) => {
    return (
        <EvtSelect
            {...props}
            options={options}
            IconComponent={(props: SelectProps) => (
                <EvtBox className={`evt-select-icon ${props.className}`} sx={selectIcon}>
                    <KeyboardArrowDownIcon />
                </EvtBox>
            )}
        />
    )
}

export default EvtCustomSelect
